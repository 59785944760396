import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faFolderOpen, faFlask, faLaptop, faEye, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faFolderOpen, faFlask, faLaptop, faEye, faQuestionCircle);

const folderOpen = icon({ prefix: 'fas', iconName: 'folder-open' });
const flask = icon({ prefix: 'fas', iconName: 'flask' });
const laptop = icon({ prefix: 'fas', iconName: 'laptop' });
const eye = icon({ prefix: 'fas', iconName: 'eye' });
const questionCircle = icon({ prefix: 'fas', iconName: 'question-circle' });

import 'bootstrap';
import 'bootstrap5-toggle';
import 'bsky-embed/dist/bsky-embed.es.js'

import { globeJS } from './components/globe';
import { getCookie } from './components/cookies';
import { showAnimation } from './components/animation';
import { animateIcons } from './components/icons-animation';
import { initAPI, highchartsPalette } from './utils';

import './styles/style.css';
import './styles/theme.css';
import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import aerovalLogo from './assets/images/logo/full_logo.svg';
import globe from './assets/images/globe.svg';
import glissEtAl from './assets/images/gliss2020_heatmap.webp';
import superComputer from './assets/images/pexels-manuel-geissinger-325229.webp';
import lightBulb from './assets/images/pexels-burak-k-132340.webp';
import privacy from './assets/images/erol-ahmed-d3pTF3r_hwY-unsplash.webp'

import { renderHeader } from './components/header';
import { renderFooter } from './components/footer';

/*import interfaces*/
import { Aeroval } from './types/global';

// Extend the Globals interface
interface ExtendedGlobals extends Aeroval {
	cards: any[];
	counts: {
		[key: string]: {
			n: number;
			name: string;
			icon: string;
		};
	};
	obsArray: string[];
	modArray: string[];
}
declare var aeroval: ExtendedGlobals;

// Extend the Window interface to include your custom property
declare global {
	interface Window {
		aeroval?: {
			config?: Aeroval['config'];
			data?: Aeroval['data'];
			settings: Aeroval['settings'];
		};
	}
}

// Render header
const headerContainer = document.getElementById('header');
if (headerContainer instanceof HTMLDivElement) {
	headerContainer.appendChild(renderHeader());
}

// index
document.querySelector<HTMLDivElement>('#app')!.innerHTML = `
<div id="animation"></div>
<div id="index" class="container main-content" style="visibility: hidden;">
    <div id="threejscontainer">
        <div id="threejsglobe">
            <canvas></canvas>
        </div>
        <div id="threetitle">
            <img id="globe-logo" class="rounded mx-auto d-block full-logo" src='${aerovalLogo}' style="height: 65px;" alt="logo_aeroval">
            <span style="margin-top: -60px; font-size: small">Evaluation of climate and air quality models</span>
        </div>
    </div>
    <div id="counters" class="row" style="padding: 10px; margin: 0px 0px 40px 0px; width: 100%;">
        <div id="counter_projects" class="counter col-3"></div>
        <div id="counter_experiments" class="counter col-3"></div>
        <div id="counter_models" class="counter col-3"></div>
        <div id="counter_observations" class="counter col-3"></div>
    </div>

    <div id="searchDiv" style="padding: 20px">
        <div id="keywordsForm" style="margin: 0px auto; max-width: 600px;">
            <!--i class="fas fa-search" style="color: #007bff; font-size: larger; margin: 10px; float: left;"></i-->
            <div id="magnify" style="margin: 7px 10px 0px 10px; float: left; height: 45px; width: 45px;"> 
                <div class="globe">
                  <img src='${globe}'/>
                </div>
            <div id="handle" style="transform: translate(24px,-64px)">
                <svg height="12" width="12">
                    <line x1="0" y1="0" x2="12" y2="12" style="stroke:#007bff; stroke-width:4" />
                </svg>
            </div>
            </div>
            <form method="post" autocomplete="off" action="javascript:filterByKeywords($(\" form").serialize().split('=')[1].replace(' +',''))" style="display: flex;">
                <div class="input-group mb-3 w-100">
                  <input class="form-control" id="kwForm" type="text" name="myKeywords" placeholder="Filter by keywords. e.g: AOD, EMEP, Trends, ...">
                </div>
            </form>
        </div>
    </div>

    <div id="container-cards" class="container-cards" style="max-width: 900px; margin: 40px auto">
        <div id="cards"></div>
        <div id="noData" style="display: none">
            <div class="alert alert-light" role="alert">
                No project corresponds to specified keywords.
            </div>
        </div>
    </div>
</div>
</div>
`;

// about
document.querySelector<HTMLDivElement>('#app')!.innerHTML += `
<div id="about" class="container justify-content-center main-content">
	<h4>AeroVal</h4>
    <div id="aeroval-div">
        <p>
            AeroVal is a web platform for the evaluation of climate and air quality models used in several projects (CMIP, AeroCom, CAMS, ...). AeroVal makes us of the <a href="#pyaerocom">pyaerocom</a> library in order to colocate models data with observations from several ground-based observation networks (EBAS, EEA, Aeronet, ...) or satellites (MODIS, AATSR, ...) and compute statistics such as biases and correlations.
            The dynamic plots allow for an easy exploration of the data, models intercomparisons, and regional statistics.
        </p>
        <figure>
            <img id="img-heatmap" class="img-fluid" src='${glissEtAl}'>
            <figcaption style="font-style: italic">Figure 1: Overall evaluation heatmap (Normalized Mean Bias) of models and observations used in <a href="https://acp.copernicus.org/articles/21/87/2021/">Gliss et al., 2020</a>.</figcaption>
        </figure>
    </div>
    <h4>pyaerocom</h4>
    <div id="pyaerocom-div">
      <p>
        <a href="http://aerocom.met.no/pyaerocom">pyaerocom</a> is written and tested for Python >= 3.6 and for unix based systems (Windows users, please information below in Getting started section). pyaerocom provides tools for processing and plotting of data related to the AEROCOM-project.
      </p>
      <p>
        This includes reading and processing of gridded data (e.g. model or satellite data, e.g. NetCDF files) and ungridded data (e.g. observational data from AERONET or EBAS networks, e.g. ASCII files) as well as tools for colocation and cross evaluation of different datasets.
      </p>
      <!--
      <p>
        The <a href="https://aerocom.met.no/">AEROCOM-project</a> is an open international initiative of scientists interested in the advancement of the understanding of the global aerosol and its impact on climate. A large number of observations (including MODIS, POLDER, MISR, AVHHR, SEAWIFS, TOMS, AATSR, AERONET and surface concentrations) and results from more than 14 global models have been assembled to document and compare state of the art modeling of the global aerosol. A common protocol has been established and models are asked to make use of the AEROCOM emission inventories for the year 2000 and preindustrial times. Results are documented via interactive websites which give access to 2D fields and standard comparisons to observations. Regular workshops are held to discuss findings and future directions.
      </p>
      <p>
        This repository contains the aerocom python tools which are / will be used to produce the standard aerocom analyses shown at the <a href="https://aerocom.met.no/cgi-bin/AEROCOM/aerocom/surfobs_annualrs.pl">aerocom phase 2 interface</a>
      </p>
      <p>
        At this point the tools are work in progress and will develop into a replacement for the IDL based aerocom-tools that cannot be made public because they use 3rd party libraries with a non GPL compatible license.
      </p>
      -->
    </div>
</div>
`;

// news
document.querySelector<HTMLDivElement>('#app')!.innerHTML += `
<div id="news" class="container justify-content-center main-content">
<h4>News and Updates</h4>
<!--div id="news-feed"></div-->
 <bsky-embed
    username="aeroval.bsky.social"
    feed="at://...(decide between username, feed, or search)"
    search="#BuildInPublic (decide between username, feed, and search)"
    mode=${aeroval.settings.theme}
    limit="5"
    link-target="_blank"
    link-image="true"
    load-more="true"
    disable-styles="false"
    custom-styles=".border-slate-300 { border-color: rgba(120,120,120,0.1); }"
    date-format='{"type":"absolute","locale":"en-EN","options":{"weekday":"short","year":"numeric","month":"short","day":"numeric"}}'
  >
  </bsky-embed>
</div>
`;

// contribute
document.querySelector<HTMLDivElement>('#app')!.innerHTML += `
<div id="contribute" class="container justify-content-center main-content">
<h4>Contribute with your data</h4>
      <div id="questions">
          <figure style="margin: 40px 0px 40px 0px">
            <img class="img-fluid mx-auto d-block" style="height: 400px; width: 100%; object-fit: cover;" src='${superComputer}'>
          </figure>
          If you want to visualize your data in AeroVal, there are two options available at present day. 
          With the current implementation, we will have to prepare a couple of things prior to visualization. 
          Please contact <b>Augustin Mortier</b> (augustinm@met.no) to get started.
          
          <h5>1. Ask us</h5>
          For punctual experiments, you can send us a request and we can run your experiment ourselves, if pyaerocom supports your data.
          
          <h5>2. DIY</h5>
          If you prefer to Do It Yourself, you can follow this recipe:
          <ul>
            <li>Download <a href="https://github.com/metno/pyaerocom">pyaerocom</a></li>
            <li>Design your experiment with a <a href="https://pyaerocom.readthedocs.io/en/latest/aeroval-examples.html">configuration</a> file</li>
            <li>Run your experiment (to be documented)</li>
            <li>Share the preprocessed JSON files with us</li>
              If you make the data available, we can fetch it, for example
              <ul>
                <li>via ftp</li>
                <li>via git</li>
              </ul>
          </ul>
          </ol>

          <h5>Some remarks</h5>
          <ul>
            <li>If pyaerocom does not know how to read your data yet, please leave an <a href="https://github.com/metno/pyaerocom/issues">issue</a></li>
            <li>For both options, your experiment can be private or public, if you want other users to look at it or not.</li>
          </ul>
          <p>
            We are continuously developing AeroVal. If your experience is not as easy and smooth as expected, do not hesitate to contact us!
          </p>
      </div>
</div>
`;

// contact
document.querySelector<HTMLDivElement>('#app')!.innerHTML += `
<div id="contact" class="container justify-content-center main-content" style="min-height: calc(100% - 250px) !important; height: auto !important; display: block">
<h4>Questions? Feedback?</h4>
      <div id="questions">
          <figure style="margin: 40px 0px 40px 0px">
              <img class="img-fluid mx-auto d-block" style="height: 400px; width: 100%; object-fit: cover;" src='${lightBulb}'>
            </figure>
          <p>
              ${questionCircle.html}
              AeroVal is a collaborative work and has involved number of people working on the different steps required by a model evaluation process: 
              data reading and processing (<i>back-end</i>), and data visualization (<i>front-end</i>).
              For any general question or feedback on AeroVal, please contact us at aeroval@met.no.
          </p>
          <p>
		  	${flask.html}
            The experiments can be run or submitted by anybody. In order to get more information about a specific experiment, you can contact directly the PI experiment reported in the description box.
      </div>
</div>
`;

// privacy
document.querySelector<HTMLDivElement>('#app')!.innerHTML += `
<div id="privacy" class="container justify-content-center main-content" style="min-height: calc(100% - 250px) !important; height: auto !important; display: block">
	<h4>Privacy policy</h4>
	<figure style="margin: 40px 0px 40px 0px">
		<img class="img-fluid mx-auto d-block" style="height: 400px; width: 100%; object-fit: cover;" src='${privacy}'>
	</figure>

	<h5>Information we collect</h5>
	<p>We collect information necessary to provide and improve our services, including user settings (theme, line-style, etc.).</p>

	<h5>Use of information</h5>
	<p>We use the collected information solely to enhance your experience on our website. We do not share your information with third parties for marketing purposes.</p>

	<h5>Cookies and embedded content</h5>
	<p>We use essential cookies to store user settings. Additionally, we embed content from third-party services such as Bluesky in the News section. These services may collect personal data and use cookies. By using our site, you consent to the use of these cookies. For more information, please refer to the privacy policies of the respective services.</p>

	<h5>Your rights</h5>
	For any privacy-related concerns, please contact us at aeroval@met.no.</p>
</div>
`;



// Render footer
const footerContainer = document.getElementById('footer');
if (footerContainer instanceof HTMLDivElement) {
	footerContainer.appendChild(renderFooter());
}

function filterByKeywords(keywords: string) {
	keywords = keywords.replace('_', ' ');
	var shown_cards = 0;
	if (keywords != '') {
		for (let key in aeroval.cards) {
			let card = aeroval.cards[key];
			let cardElement = document.getElementById(card.id);
			if (cardElement && cardElement.parentElement) {
				if (!card.keywords.includes(keywords)) {
					cardElement.parentElement.style.display = 'none';
				} else {
					cardElement.parentElement.style.display = 'inline-block';
					shown_cards++;
				}
			}
		}
	} else {
		for (let key in aeroval.cards) {
			let card = aeroval.cards[key];
			let cardElement = document.getElementById(card.id);
			if (cardElement && cardElement.parentElement) {
				cardElement.parentElement.style.display = 'inline-block';
				shown_cards++;
			}
		}
	}
	const noDataElement = document.getElementById('noData');
	if (noDataElement) {
		if (shown_cards == 0) {
			noDataElement.style.display = 'initial';
		} else {
			noDataElement.style.display = 'none';
		}
	}
}

function checkKey(event: KeyboardEvent, div: string): void {
	var code = event.key;
	var form = document.getElementById(div) as HTMLInputElement;
	if (code === 'Enter' || form.value == '') {
		filterByKeywords(form.value);
	}
}

document.addEventListener('DOMContentLoaded', () => {
	init();
});

function init(): void {
	console.log('initialize index page');
	initAPI();

	const delay = 4000;
	const fade = 400;
	const theme = getCookie('theme') as Aeroval['Theme'];

	if (!window.location.href.endsWith('/') || document.referrer != '' || aeroval.settings.animation !== 'true') {
		//does not show animation if click on portal
		const animationElement = document.getElementById('animation');

		if (animationElement) {
			// Remove classes
			animationElement.classList.remove('d-flex', 'justify-content-center', 'align-items-center');
			// Set display to "none"
			animationElement.style.display = 'none';
		}

		const hash: string = new URL(window.location.href).hash;
		if (hash) {
			const contentMainIndexElement = document.querySelector(hash);
			if (contentMainIndexElement) {
				contentMainIndexElement.setAttribute('style', 'min-height: calc(100% - 250px); display: block !important');
			}
		} else {
			const contentMainIndexElement = document.querySelector('#index');
			if (contentMainIndexElement) {
				contentMainIndexElement.setAttribute('style', 'min-height: calc(100% - 250px); display: block !important');
			}
		}


		// launch globe animation
		globeJS();
	} else {
		showAnimation(theme, delay, fade)
	}

	/*animation on scroll*/
	window.addEventListener(
		'scroll',
		() => {
			document.body.style.setProperty('--scroll', String(window.scrollY / window.innerHeight));
		},
		false
	);

	if (window.location.host.includes('cams2-83') || window.location.host.includes('regional-evaluation.atmosphere.copernicus.eu')) {
		location.href = `./pages/evaluation/?project=cams2-83`;
	} else if (window.location.host.includes('cams2-82') || window.location.host.includes('global-eqc-server.atmosphere.copernicus.eu')) {
		location.href = `./pages/evaluation/?project=cams2-82`;
	}

	// load projects
	iniCounts();
	getProjects();
	filterProjects();
	animateIcons();
}

function filterProjects() {
	const inputElement = document.getElementById('kwForm') as HTMLInputElement;
	if (inputElement) {
		inputElement.addEventListener('keyup', (event: KeyboardEvent) => {
			// Call your checkKey function passing the event and the id of the div
			checkKey(event, 'kwForm');
		});
	}
}

function getProjects(): void {
	if (window.DATA_PATH == '') {
		fetch('./config/projects.json')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				aeroval.config.projects = data;
				fillCards(data);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	} else {
		const url = `${window.API_ROOT}/projects/${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				var projects: any = {}
				for (const project of data) {
					projects[project] = {
						"url": `./pages/evaluation/?project=${project}`,
						"title": project,
						"subtitle": "",
						"description": "No description",
						"img": "",
						"enabled": true,
						"display": true,
						"keywords": []
					}
				}
				aeroval.config.projects = projects;
				fillCards(projects);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
		addUserIcon()
	}

}

function addUserIcon() {
	const element = document.getElementById("globe-logo");
	var h = `<span class="position-absolute start-100 translate-middle badge rounded-pill bg-secondary" style="font-size: xx-small">`
	h += `${window.DATA_PATH}`
	h += `</span>`
	if (element) {
		element.insertAdjacentHTML('afterend', h);
	}
}

function fillCards(data: Aeroval['config']['projects']): void {
	if (!data) return;

	const keys = Object.keys(data);

	var j = 0; //card with no picture
	var h = '<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">';
	var Keywords = [] as string[];
	aeroval.cards = [];

	for (let key of keys) {
		const display = data[key].display ? 'inline-block' : 'none';
		const enabled = data[key].enabled ? 'enabled' : 'disabled';
		const id = data[key].title.replace(' ', '_');

		if (display == 'none') continue;
		h += `<div class="col">`
		h += `<div id="${id}" class="card ${enabled}">`;

		//image
		if (data[key].img == '') {
			h += `<div class="container card-span-top" style="background-color: ${highchartsPalette(j)}"><span> ${data[key].title} </span></div>`;
			j++;
		} else {
			h += `<img class="card-img-top" src="${data[key].img}" alt="Card image cap">`;
		}

		h += `<div class="card-body">
			<h5 class="card-title"> ${data[key].title} </h5>
			<h6 class="card-subtitle mb-2 text-muted">${data[key].subtitle}</h6>
			<p class="card-text">${data[key].description}</p>`;
		if (data[key].enabled) {
			//if several urls, then several buttons
			if (Array.isArray(data[key].url) && Array.isArray(data[key].subprojects)) {
				const urls = data[key].url as string[];
				const subprojects = data[key].subprojects as string[];
				for (let j = 0; j < urls.length; j++) {
					if (j <= urls.length && j <= subprojects.length) {
						h += `<a href="${urls[j]}" class="btn btn-primary" style="margin-right: 5px">${subprojects[j]}</a>`;
					}
				}
			} else {
				h += `<a href="${data[key].url}" class="btn btn-primary stretched-link">${data[key].title}</a>`;
			}

			//add keywords
			Keywords = Keywords.concat(data[key].title);
			if (typeof data[key].keywords != 'undefined') {
				Keywords = Keywords.concat(data[key].keywords);
			}
			aeroval.cards.push({
				id: id,
				keywords: data[key].keywords.concat(data[key].title),
			});
		} else {
			h += `<a href="${data[key].url}" class="btn btn-primary stretched-link disabled">${data[key].title}</a>
				<br><span class="coming-soon"> Coming Soon</span>`
		}
		h += `</div>
			</div>
			</div>`
	}
	h += '</div>';

	const cards = document.querySelector('#cards');
	if (cards instanceof HTMLElement) {
		cards.innerHTML = h;
	}

	//remove duplicate keywords
	Keywords = Keywords.filter(onlyUnique);
	//send the list of stations to the statForm
	const kwForm = document.getElementById('kwForm');
	if (kwForm instanceof HTMLInputElement) {
		autocomplete(kwForm, Keywords);
	}
}

function onlyUnique(value: string, index: number, self: string[]) {
	return self.indexOf(value) === index;
}

function autocomplete(input: HTMLInputElement, arr: string[]) {
	/*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
	var currentFocus: number = 0;
	/*execute a function when someone writes in the text field:*/
	input.addEventListener('input', function () {
		var a,
			b,
			i,
			val = this.value;
		/*close any already open lists of autocompleted values*/
		closeAllLists();
		if (!val) {
			return false;
		}
		currentFocus = -1;
		/*create a DIV element that will contain the items (values):*/
		a = document.createElement('DIV');
		a.setAttribute('id', this.id + 'autocomplete-list');
		a.setAttribute('class', 'autocomplete-items');
		/*append the DIV element as a child of the autocomplete container:*/
		this?.parentNode?.appendChild(a);
		/*for each item in the array...*/
		for (i = 0; i < arr.length; i++) {
			/*check if the item starts with the same letters as the text field value:*/
			if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
				/*create a DIV element for each matching element:*/
				b = document.createElement('DIV');
				/*make the matching letters bold:*/
				b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
				b.innerHTML += arr[i].substr(val.length);
				/*insert a input field that will hold the current array item's value:*/
				b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
				/*execute a function when someone clicks on the item value (DIV element):*/
				b.addEventListener('click', function () {
					/*insert the value for the autocomplete text field:*/
					input.value = this.getElementsByTagName('input')[0].value;
					/*close the list of autocompleted values,
				  (or any other open lists of autocompleted values:*/
					closeAllLists();
					const inputElement = document.getElementById('kwForm') as HTMLInputElement | HTMLTextAreaElement | null;
					if (inputElement) {
						filterByKeywords(inputElement.value);
					}
				});
				a.appendChild(b);
			}
		}
	});
	/*execute a function presses a key on the keyboard:*/
	input.addEventListener('keydown', (e) => {
		let x: HTMLDivElement[] | undefined = undefined;
		let tempX = document.getElementById(input.id + 'autocomplete-list');
		if (tempX) {
			x = Array.from(tempX.getElementsByTagName('div')) as HTMLDivElement[];
		}
		if (e.key == 'ArrowDown') {
			currentFocus++;
			addActive(x);
		} else if (e.key == 'ArrowUp') {
			currentFocus--;
			addActive(x);
		} else if (e.key == 'Enter') {
			e.preventDefault();
			if (currentFocus > -1) {
				if (x) x[currentFocus].click();
			}
		}
	});

	function addActive(x: HTMLDivElement[] | undefined) {
		/*a function to classify an item as "active":*/
		if (!x) return false;
		/*start by removing the "active" class on all items:*/
		removeActive(x);
		if (currentFocus >= x.length) currentFocus = 0;
		if (currentFocus < 0) currentFocus = x.length - 1;
		/*add class "autocomplete-active":*/
		x[currentFocus].classList.add('autocomplete-active');
	}

	function removeActive(x: HTMLDivElement[] | undefined) {
		if (!x) return;
		/*a function to remove the "active" class from all autocomplete items:*/
		for (var i = 0; i < x.length; i++) {
			const element = x[i];
			if (element instanceof HTMLElement) {
				x[i].classList.remove('autocomplete-active');
			}
		}
	}

	function closeAllLists(element: EventTarget | null = null): void {
		// close all autocomplete lists in the document, except the one passed as an argument
		document.querySelectorAll('.autocomplete-items').forEach((item) => {
			if (element != item && element != input) {
				item?.parentNode?.removeChild(item);
			}
		});
	}
	/*execute a function when someone clicks in the document:*/
	document.addEventListener('click', function (e) {
		closeAllLists(e.target);
	});
}

function iniCounts(): void {
	aeroval.counts = {
		projects: {
			n: 0,
			name: 'projects',
			icon: folderOpen.html[0],
		},
		experiments: {
			n: 0,
			name: 'experiments',
			icon: flask.html[0],
		},
		models: {
			n: 0,
			name: 'climate and aq models',
			icon: laptop.html[0],
		},
		observations: {
			n: 0,
			name: 'observation datasets',
			icon: eye.html[0],
		},
	};
	var counts = Object.keys(aeroval.counts);
	for (let i: number = 0; i < counts.length; i++) {
		var h = '';
		h += `<div class="count_icon">${aeroval.counts[counts[i]].icon}</div>
			<span id="counter_${counts[i]}_n" class="count_n">${0}</span>
			<span class="count_category d-flex align-items-center justify-content-center">${aeroval.counts[counts[i]].name}</span>`;
		var counter = document.getElementById(`counter_${counts[i]}`);
		if (counter instanceof HTMLElement) {
			counter.innerHTML = h;
		}
	}

	/*get actual data*/
	getCounts();
}

function getCounts(): void {
	if (window.DATA_PATH == '') {
		fetch('./config/projects.json')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				countProjects(data);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	} else {
		const url = `${window.API_ROOT}/projects/${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				var projects: any = {}
				for (const project of data) {
					projects[project] = {
						"url": `./pages/evaluation/?project=${project}`,
						"title": project,
						"subtitle": "",
						"description": "No description",
						"img": "",
						"enabled": true,
						"display": true,
						"keywords": []
					}
				}
				aeroval.config.projects = projects;
				countProjects(projects);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	}
}

function countProjects(data: any): void {
	aeroval.obsArray = [];
	aeroval.modArray = [];
	getProjectExperiments(data);
}

async function getProjectExperiments(data: any) {
	const projects = Object.keys(data);
	const enabledProjects = []
	const promises = [];

	for (const project of projects) {
		if (data[project].enabled && data[project].display) {
			const url = `${window.API_ROOT}/experiments/${project}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
			promises.push(fetch(url));
			enabledProjects.push(project)
		}
	}

	const responses = await Promise.allSettled(promises);

	// Filter out fulfilled promises and map responses to JSON
	const fulfilledResponses = responses.filter((response) => response.status === 'fulfilled').map((response) => (response as any).value.json());

	// Await parsing of JSON responses
	const projectsJSON = await Promise.all(fulfilledResponses);

	var i = 0
	for (const projectJSON of projectsJSON) {
		if (projectJSON) {
			try {
				var exps = Object.keys(projectJSON);
				aeroval.counts.projects.n++;
				getExperimentsMenus(enabledProjects[i], exps);
			} catch (error) {
				console.warn(`Error: Failed to parse JSON for ${projectJSON.url}`);
			}
		}
		i++
	}
}

async function getExperimentsMenus(project: string, experiments: string[]) {
	// list experiments files
	const promises = [];

	// Loop through each experiment to create promises for fetching files
	for (const experiment of experiments) {
		aeroval.counts.experiments.n++;
		const url = `${window.API_ROOT}/menu/${project}/${experiment}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
		promises.push(fetch(url));
	}
	// update projects and experiments counter
	updateCount('projects', 0, aeroval.counts.projects.n, 500);
	updateCount('experiments', 0, aeroval.counts.experiments.n, 500);

	// Use Promise.allSettled() to fetch all files concurrently
	const responses = await Promise.allSettled(promises);

	// Filter out fulfilled promises and map responses to JSON
	const fulfilledResponses = responses.filter((response) => response.status === 'fulfilled').map((response) => (response as any).value.json());

	// Await parsing of JSON responses
	const menus = await Promise.all(fulfilledResponses);

	for (const menu of menus) {
		for (let variable in menu) {
			for (let obs in menu[variable].obs) {
				const obs_net = `${variable}-${obs}`;
				if (!aeroval.obsArray.includes(obs_net)) {
					aeroval.obsArray.push(obs_net);
				}
				for (let layer in menu[variable].obs[obs]) {
					//models
					for (let model in menu[variable].obs[obs][layer]) {
						if (!aeroval.modArray.includes(model)) {
							aeroval.modArray.push(model);
						}
					}
				}
			}
		}
	}
	// update observation and model counters
	updateCount('observations', 0, aeroval.obsArray.length, 500);
	updateCount('models', 0, aeroval.modArray.length, 500);
}

function updateCount(which: string, start: number, end: number, duration: number): void {
	var counter = document.getElementById(`counter_${which}_n`);
	let startTimestamp = 0;
	if (!window.location.href.endsWith('/') || document.referrer != '' || aeroval.settings.animation !== 'true') {
		const step = (timestamp: number) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			if (counter instanceof HTMLElement) {
				counter.innerHTML = String(Math.floor(progress * (end - start) + start));
			}
			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
		};
		window.requestAnimationFrame(step);
	} else {
		//delay animation
		window.setTimeout(function () {
			const step = (timestamp: number) => {
				if (!startTimestamp) startTimestamp = timestamp;
				const progress = Math.min((timestamp - startTimestamp) / duration, 1);
				if (counter instanceof HTMLElement) {
					counter.innerHTML = String(Math.floor(progress * (end - start) + start));
				}
				if (progress < 1) {
					window.requestAnimationFrame(step);
				}
			};
			window.requestAnimationFrame(step);
		}, 4000);
	}
}
